<template>

	<div class="columns">
		<div class="column is-three-fifths is-offset-one-fifth">
			<h1  class="title">
				Testeur de réactions
			</h1>
			<form @submit.prevent="loadEmbed">
				<div class="field">
					<label class="label">Story ID</label>
					<div class="control">
						<input class="input" type="text" placeholder="Identifiant de l'article" v-model="storyId">
					</div>
				</div>
				<div class="field">
					<label class="label">Site</label>
					<div class="control">
						<div class="select">
							<select v-model="site">
								<option v-for="tmpSite in sites">{{tmpSite}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="field">
					<label class="label">Token JWT</label>
					<div class="control">
						<input class="input" type="text" placeholder="Token SSO JWT" v-model="jwtToken">
					</div>
				</div>

				<div class="field is-grouped">
					<div class="control">
						<button class="button is-link">Afficher</button>
					</div>
				</div>				
			</form>
			<br>
			<div v-if="dataReady">
				<center>
					<button @click="openUrl" class="button is-small is-outlined">Ouvrir dans une nouvelle fenêtre</button>
				</center>
				<br>
				<div class="box">
					<div id="reactions-wrapper"></div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	export default {
		name : 'Testeur',
		data() {
			return {
				sites : ['sofoot'],
				storyId : '_458539',
				site : false,
				loaded:false,
				jwtToken:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiIzMDJlMzUzMC0zMjM4LTQwMzYtYjAzMC0yMDMxMzUzODM2MzMiLCJleHAiOjE1ODkwMjk4NzUsImlhdCI6MTU4NjM1MTQ3NSwidXNlciI6eyJuYW1lIjoiR2lsb3UiLCJzc29JZCI6Il8yIiwiZW1haWwiOiJnaWxsZXMuZnJhbmNvaXNAc29mb290LmNvbSIsImF2YXRhciI6IiIsImJhZGdlIjoiU1RBRkYifX0.okZZQ6RKGZZbCkTqhPQ9AXm3wSkxp_sc-fHka7KmZuA'
			}
		},
		mounted() {
			if(this.sites.length) {
				this.site = this.sites[0];
			}
			let url = '/static/embed.js?'+Math.random();
			this.loadJs(url,() => {
				// this.loadEmbed()
			});
		},
		computed : {
			dataReady() {
				return this.loaded && this.storyId && this.site;
			}
		},
		methods : {
			openUrl() {
				window.open(document.querySelector('iframe').src,'_blank')
			},
			loadEmbed() {
				this.loaded = true;
				setTimeout(() => {

					Reactions({
						storyId:this.storyId,
						jwtToken : this.jwtToken,
						reactionsUrl : this.siteUrl()+'/'+this.site
					});
				},500);

			}
		}
	}

</script>